import React, { useState, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { createUseStyles } from 'react-jss';

import { motion, AnimatePresence } from 'framer-motion';

import { ReactComponent as ListView } from '../img/prox_units-list-view.svg';
import { ReactComponent as GridView } from '../img/prox_units-grid-view.svg';
import ConstantData from 'data/ConstantData';

import useSavedHomes from 'hooks/useSavedHomes';
import useGlobalUnitsFilter from 'hooks/useGlobalUnitsFilter';
import useSiteFeatureToggles from 'hooks/useSiteFeatureToggles';
import FilterHeader from './FilterHeader';

import {
  portraitMode,
  sortDataByName,
  determineFloorPlanStatus,
  translateUnitStatus,
} from 'util/helper';
import { AVAILABLE, FINAL_HOME, FINAL_UNIT } from '../constants';

const useStyles = createUseStyles(
  {
    wrapper: {
      // height: "100%",
      height: '100vh',
      // overflow: "hidden",
      '--filterHeaderHeight': (props) =>
        props.isPortraitMode ? (props.twoTile ? '40px' : '1px') : '90px',
    },
    filterHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      boxShadow: ' 0.5px 0.5px 0px 0.5px rgba(0, 0, 0, 0.05)',
      height: (props) => (props.twoTile ? 31 : 'auto'),
      borderBottom: (props) =>
        props.isPortraitMode
          ? `1px solid ${props.ThemeData.colours.lightTan}`
          : 0,

      '& > div': {
        color: (props) => props.ThemeData.colours.vibrantDarkBlue,
      },
    },
    filterCategoryControls: {
      flex: '1 0 auto',
    },
    filterButtons: {
      height: 50,

      flex: '0 0 auto',

      padding: '0 50px',
    },
    filterButton: (props) => ({
      ...props.ThemeData.styles.roundedCorners,
    }),
    filterViewControls: {
      flex: '0 0 auto',
      height: 50,
      display: 'flex',
      alignItems: 'center',

      padding: '0 2% 0 0',

      fontSize: 32,
      color: (props) => props.ThemeData.colours.primaryFontHighestContrast,
      marginLeft: 'auto',
      '& > div': {
        flex: '0 0 auto',
        display: 'inline-block',
        marginLeft: (props) => (props.isPortraitMode ? 5 : 20),
      },
    },
    filterViewIcon: {
      cursor: 'pointer',
      color: '#49656B',
    },
    filterViewIconSelected: {
      color: (props) => props.ThemeData.colours.primaryAccent,
      cursor: 'default',
    },
    filterList: {
      color: (props) => props.ThemeData.colours.primaryFont,
    },
    filterRange: (props) => ({
      color: props.ThemeData.colours.secondaryFont,
      backgroundColor: props.ThemeData.colours.secondaryBg,
    }),
    wrapperSizing: {
      height: 'calc(100% - 225px)',
      overflowY: 'auto',
    },
    paneSizing: {
      // height: "100%",
      // height:'fi',
      // height: "calc( 100% - 9vh )",
      // border: "4px solid red",
    },
    fullItemsWrapper: (props) => ({
      // height: "130vh",
      height: 'maxContent',
      // border: "1px solid red",
      padding: props.isPortraitMode
        ? props.twoTile
          ? '0 10px 0 10px'
          : '0 60px'
        : '0 20px 0 0',
      ...props.ThemeData.styles.thinScrollbarDarkOnLight,

      '&.noFilterSort': {
        maxHeight: 'calc( 100% - 50px )',
      },
    }),
    dualPaneWrapper: {
      display: 'flex',
    },
    dualPanePane: {
      width: '50%',
      boxSizing: 'border-box',
    },
    dualPaneLeftPane: {
      height: '100%',
    },
    unitCards: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: (props) =>
        props.isPortraitMode
          ? 'space-evenly'
          : props.isModelPage || props.isSiteMapPage
            ? 'space-evenly'
            : 'flex-start',
      boxSizing: 'border-box',
      paddingTop: (props) =>
        props.isPortraitMode ? (props.calledByFloorplan ? 10 : 0) : 20,
      paddingLeft: (props) => (props.isPortraitMode ? '' : '1.5%'),
      paddingRight: (props) => (props.isPortraitMode ? '' : '1.5%'),
    },
    bgAmp: (props) => ({
      zIndex: -1,
      position: 'absolute',
      left: -50,
      bottom: -100,

      color: props.ThemeData.colours.primaryAccent08,
      fontFamily: props.ThemeData.fonts.copy,
      fontSize: 500,
    }),
    contentContainer: {
      display: 'flex',
      flexDirection: 'column',

      width: '100%',
      height: '100%',
    },
    topRightContainer: {
      position: 'absolute',
      top: 60,
      right: 60,

      display: 'flex',
      alignItems: 'center',
    },
    closeX: {
      marginTop: 10,
      marginLeft: 50,

      cursor: 'pointer',
    },
    sendToEmailButton: {
      display: 'inline-block',

      width: 150,

      fontSize: 12,
      letterSpacing: '.1em',
    },
    savedHomesTitle: {
      textAlign: 'left',

      fontSize: 24,
      letterSpacing: '.15em',
    },
    top: {
      flex: '0 0 auto',
    },
    addHomesButton: {
      width: 220,

      fontSize: 12,
      letterSpacing: '.1em',

      borderRadius: 25,
    },
    middle: (props) => ({
      flex: '1 0 auto',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-evenly',

      maxHeight: 'calc( 100vh )',

      overflowY: 'auto',

      ...props.ThemeData.styles.thinScrollbar,
    }),
    unitCardWrapper: {
      flex: '0 0 auto',
      width: (props) => (props.calledByFloorplan ? '25%' : 'auto'), //22%
      minWidth: 'auto',
      maxWidth: 'auto',
      padding: (props) =>
        props.isPortraitMode ? '0 10px 40px 28px' : '0 1.5% 1% 1.5%',
    },

    unitCardWrapperPortrait: (props) => ({
      flex: '0 0 auto',
      padding: props.twoTile
        ? '5px 0'
        : props.calledByFloorplan
          ? '0 10px 34px 10px'
          : '10px 30px 10px 0',
      width: props.twoTile ? 220 : props.oneTile ? '90%' : '458',
      minWidth: props.oneTile ? 325 : 'auto',
      maxWidth: props.oneTile ? 450 : 'auto',
    }),
    unitCard: {
      color: (props) => props.ThemeData.colours.black,
    },
    tableHeaderRow: (props) => ({
      height: 50,
      display: 'flex',
      alignItems: 'center',

      color: props.ThemeData.colours.primaryFont,
      fontFamily: props.ThemeData.fonts.title,
    }),
    tableHeaderItem: {
      flex: '1 0 auto',
      width: 100,
    },

    bottom: {
      flex: '0 0 auto',
      display: 'flex',

      boxSizing: 'border-box',
      paddingTop: 25,

      height: 130,

      borderTop: (props) => `1px ${props.ThemeData.colours.white50} solid`,
    },
    filterProducesNoResultsNotice: {
      marginTop: 10,
      color: (props) => props.ThemeData.colours.black,
    },
    filteredResultsHeading: {
      color: (props) => props.ThemeData.colours.neuDeepGrey,
      marginLeft: 24,
    },
    disclaimerBox: {
      height: '100px',
      marginBottom: '80px',
    },
    disclaimer: {
      color: 'rgba(0, 0, 0, 0.75)',
      textAlign: 'center',
      marginLeft: 20,
      fontStyle: 'italic',
      fontWeight: 100,
    },
    icon: {
      cursor: 'pointer',
    },
    filteredResultTitle: {
      display: 'flex',
      fontSize: 24,
      color: (props) => props.ThemeData.colours.black,
      padding: '0 40px 20px 40px',
      marginBottom: 20,
      height: 210,
      borderBottom: (props) => `1px ${props.ThemeData.colours.lightTan} solid`,

      '& > div': {
        alignSelf: 'flex-end',
      },
    },
  },
  {
    name: 'UnitCardsParent',
  },
);

const RenderUnitCardsParent = (
  project,
  isPortraitMode,
  classes,
  data,
  filteredUnitsData,
  error,
  isOpen,
  toggleIsOpen,
  hooks,
  props,
) => {
  const {
    ThemeData,
    filterSettings: { hideFloorPlanFilter },
  } = project;
  const location = useLocation();

  if (error) {
    console.log('RenderFloorplans', error.message);
  }

  return (
    <>
      <div className={classes.wrapper}>
        {props.addFilteredResult && !isPortraitMode && (
          <div className={classes.filteredResultTitle}>
            <div>Showing {filteredUnitsData.length} homes</div>
          </div>
        )}
        {((!props.dontShowFilter &&
            !props.dontShowFilterSort &&
            !isPortraitMode) ||
          props.twoTile) && (
          <div className={classes.filterHeader}>
            {props.addFilteredResult && (
              <div style={{ paddingLeft: 10 }}>
                Showing {filteredUnitsData.length} homes
              </div>
            )}
            {!location.pathname.includes('/proximation') &&
              !props.isSiteMapPage && (
                <FilterHeader
                  project={project}
                  hideFilter={true}
                  hideAllFilters={hideFloorPlanFilter}
                  doShowFilter={props.doShowFilter}
                  setDoShowFilter={props.setDoShowFilter}
                />
              )}
            <div className={classes.filterViewControls}>
              {(!isPortraitMode || props.twoTile) && (
                <>
                  <div
                    className={classes.controlIconGrid}
                    id="unit-detail-card-view-toggle">
                    <GridView
                      className={classes.icon}
                      id="unit-detail-card-view-toggle"
                      onClick={() =>
                        props.setUnitFilterViewMode(
                          ConstantData.unitFilterViewModes.card,
                        )
                      }
                      fill={
                        props.unitFilterViewMode ===
                        ConstantData.unitFilterViewModes.card
                          ? ThemeData.colours.primaryAccent
                          : ThemeData.colours.neuDarkBlueFade
                      }
                    />
                  </div>
                  <div
                    className={classes.controlIconList}
                    id="unit-detail-list-view-toggle">
                    <ListView
                      className={classes.icon}
                      id="unit-detail-list-view-toggle"
                      onClick={() =>
                        props.setUnitFilterViewMode(
                          ConstantData.unitFilterViewModes.list,
                        )
                      }
                      fill={
                        props.unitFilterViewMode ===
                        ConstantData.unitFilterViewModes.list
                          ? ThemeData.colours.primaryAccent
                          : ThemeData.colours.neuDarkBlueFade
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        )}

        <div
          className={`${classes.wrapperSizing} ${
            props.dualPaneLeftPane && classes.dualPaneWrapper
          }`}>
          <AnimatePresence>
            {props.dualPaneLeftPane && (
              <motion.div
                className={`${props.dualPaneLeftPane && classes.dualPanePane}`}
                key={1}
                {...ThemeData.anim.defaultOpacityFade}>
                {props.dualPaneLeftPane}
              </motion.div>
            )}
            <motion.div
              className={`${classes.paneSizing} ${
                props.dualPaneLeftPane && classes.dualPanePane
              }`}
              key={2}
              {...ThemeData.anim.defaultOpacityFade}>
              <AnimatePresence exitBeforeEnter={true}>
                {props.dualPaneRightPane ? (
                  props.dualPaneRightPane
                ) : (
                  <motion.div
                    className={`${classes.fullItemsWrapper} ${
                      props.fullItemsWrapperClassName
                    } ${
                      !props.dontShowFilter && !props.dontShowFilterSort
                        ? ''
                        : 'noFilterSort'
                    }`}
                    {...ThemeData.anim.defaultOpacityFade}>
                    <div
                      id="unitItemsDirectParent"
                      className={`${classes.unitCards} ${props.childWrapperClassName}`}>
                      {/* <div style={{display:'block', height:'500px', width:500, border:'2px solid red', color:'black'}}>asdasdasda</div> */}
                      <AnimatePresence>
                        {props.children &&
                          filteredUnitsData &&
                          [...filteredUnitsData].map((dataUnit, idx) => {
                            return (
                              <React.Fragment key={dataUnit.id}>
                                {dataUnit?.floorPlan.building.disabled && (
                                  <motion.div
                                    id={`unit-card-${dataUnit.id}`}
                                    //unitId={dataUnit.threeDId}
                                    className={`${
                                      props.childClassName ||
                                      (isPortraitMode
                                        ? classes.unitCardWrapperPortrait
                                        : classes.unitCardWrapper)
                                    }`}
                                    key={dataUnit.id}
                                    custom={dataUnit.id}
                                    {...ThemeData.anim.defaultOpacityFade}
                                    layout="position">
                                    <div data-unitId={dataUnit.threeDId} />
                                    {props.children(
                                      classes.unitCard,
                                      dataUnit,

                                      idx,
                                      idx === 0,
                                      () => hooks.toggleSavedHome(dataUnit.id),
                                    )}
                                  </motion.div>
                                )}
                              </React.Fragment>
                            );
                          })}
                      </AnimatePresence>
                    </div>

                    <div className={classes.disclaimerBox}>
                      <p className={classes.disclaimer}>
                        {ConstantData.disclaimerData.disclaimer_2}
                      </p>

                      <p className={classes.disclaimer}>
                        {ConstantData.disclaimerData.disclaimer_3}
                      </p>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </>
  );
};

const UnitCardsParent = ({ isOpen, toggleIsOpen, ...props }) => {
  const {
    project,
    project: { filterData: data },
  } = props;
  const isPortraitMode = portraitMode();
  const location = useLocation();
  const isModelPage = location.pathname.includes('/proximation') ? true : false;

  const classes = useStyles({
    ThemeData: project.ThemeData,
    isPortraitMode,
    isModelPage,
    ...props,
  });
  const { savedHomes, toggleSavedHome } = useSavedHomes();
  const globalUnitsFilter = useGlobalUnitsFilter();
  const siteFeatureToggles = useSiteFeatureToggles(props.project);

  let hooks = {
    savedHomes,
    toggleSavedHome,
    globalUnitsFilter,
    siteFeatureToggles,
  };

  const [filteredUnitsData, setFilteredUnitsData] = useState([]);

  useEffect(() => {}, [props.selectedUnitId, props.setSelectedUnitId]);

  const unitsInBuilding = project.units.filter((unit) => {
    return unit.floorPlan.building.id === props?.buildingId;
  });

  useEffect(() => {
    if (project?.units) {
      setFilteredUnitsData(
        globalUnitsFilter.applyFilter(
          !project.settings.showUnits &&
          !props.isSiteMapPage &&
          !location.pathname.includes('/proximation')
            ? sortDataByName(data.units)
            : sortDataByName(
              location.pathname.includes('interactive')
                ? project?.settings?.isTownHouse
                  ? project.units
                  : unitsInBuilding
                : project.units,
            ),
        ),
      );
    }
  }, [globalUnitsFilter.globalUnitsFilter, project, data]);

  useEffect(() => {
    if (props.doSelectFirstItem) {
      if (
        !props.selectedUnitId ||
        (filteredUnitsData.length > 0 &&
          filteredUnitsData.filter(
            (unitData) => unitData.threeDId === props.selectedUnitId,
          ).length === 0)
      ) {
        props.setSelectedUnitId(filteredUnitsData[0].threeDId);
        return;
      }
    }
  });

  useEffect(() => {
    if (props.setDataUnitCustomId1) {
      if (data && props.compareUnitCustomId1) {
        props.setDataUnitCustomId1(
          data.units.filter(
            (dataUnit) => dataUnit.threeDId === props.compareUnitCustomId1,
          )[0],
        );
        return;
      }

      props.setDataUnitCustomId1(null);
    }
  }, [props.compareUnitCustomId1, data]);

  useEffect(() => {
    if (props.setDataUnitCustomId2) {
      if (data && props.compareUnitCustomId2) {
        props.setDataUnitCustomId2(
          data.units.filter(
            (dataUnit) => dataUnit.threeDId === props.compareUnitCustomId2,
          )[0],
        );
        return;
      }

      props.setDataUnitCustomId2(null);
    }
  }, [props.compareUnitCustomId2, data]);

  /**
   * - Scroll Unit card selected in WebGL into view if not already in view
   * - Animate card
   */
  useEffect(() => {
    if (props.setSelectedUnitData)
      props.setSelectedUnitData(
        filteredUnitsData.filter(
          (unitData) => unitData.threeDId === props.selectedUnitId,
        )[0],
      );

    if (props.selectedUnitId && props.selectedUnitId !== -1) {
      // scroll item into view

      let directParentElm = document.getElementById('unitItemsDirectParent');
      if (!directParentElm) return;

      let unitItemElm = directParentElm.querySelector(
        `[data-unitid='${props.selectedUnitId}']`,
      );
      if (!unitItemElm) return;

      setTimeout(() => {
        unitItemElm.parentElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }, 400);
    }
  }, [props.selectedUnitId]);

  // Group units by floorplan ID

  const isTownHouse = project.settings.isTownHouse;

  const groupedUnits = filteredUnitsData.reduce((acc, unit) => {
    const useIdOrName = isTownHouse ? unit.floorPlan.name : unit.floorPlan.id;

    const floorPlanId = useIdOrName;

    if (!acc[floorPlanId]) {
      acc[floorPlanId] = {
        units: [],
        floorPlanType: unit.floorPlan.floorPlanType,
        status: unit.floorPlan.status,
      };
    }
    acc[floorPlanId].units.push(unit);
    return acc;
  }, {});

  // Determine status for each floorplan
  const floorPlanStatuses = Object.keys(groupedUnits).reduce(
    (acc, floorPlanId) => {
      // floorPlanId comes through as name for townhouses, ids else
      const { units, floorPlanType, status } = groupedUnits[floorPlanId];
      acc[floorPlanId] = determineFloorPlanStatus({
        units,
        floorPlanType,
        status,
      });
      return acc;
    },
    {},
  );

  // Add status to each unit, filter out disabled units
  const modifiedFilteredUnitsData = filteredUnitsData.map((unit) => {
    const useIdOrName = isTownHouse ? unit.floorPlan.name : unit.floorPlan.id;

    return {
      ...unit,
      floorPlanStatus: floorPlanStatuses[useIdOrName],

      // If the unit is available, and the floorplan status is "finalUnit" or "finalHome",
      // it is safe to make the status of the unit the same as the status of the floorplan
      status:
        unit.status === AVAILABLE &&
        (floorPlanStatuses[useIdOrName] === FINAL_UNIT ||
          floorPlanStatuses[useIdOrName] === FINAL_HOME)
          ? floorPlanStatuses[useIdOrName]
          : translateUnitStatus(unit.status),
    };
  });

  return RenderUnitCardsParent(
    project,
    isPortraitMode,
    classes,
    data,
    modifiedFilteredUnitsData,
    // data.units,
    null,
    isOpen,
    toggleIsOpen,
    hooks,
    props,
  );
};

UnitCardsParent.defaultProps = {
  dontShowFilterSort: false,
  dontShowFilter: false,
  dontShowSort: false,
  doSelectFirstItem: false,
};

export default UnitCardsParent;
