import React, {useState, useRef, useEffect} from 'react';

import * as ReactDOMServer from 'react-dom/server';

import {Route, Switch, useLocation} from 'react-router-dom';

import {AnimatePresence} from 'framer-motion';

import {createUseStyles} from 'react-jss';
import {useMediaQuery} from 'react-responsive';

import 'mapbox-gl/dist/mapbox-gl.css';
import SlugData from './data/SlugData';
import Section from './components/Section';
import Proximation from './components/Proximation';
import PortraitProximation from './components/ProximationPortrait';
import proximaIcon from './img/ProximaHQ_Icon.svg';
import UnitDetail from 'components/UnitDetail';
import UnitDetailPortrait from 'components/UnitDetailPortrait';
import accessibilityPane from 'hooks/accessibilityPane';
import useOpenClosePane from 'hooks/useOpenClosePane';
import UnitFcnContext from 'contexts/UnitFcnContext';
import SavedHomesSlideout from 'components/SavedHomesSlideout';
import SavedHomesCompare from 'components/SavedHomesCompare';
import SavedHomesComparePortrait from 'components/SavedHomesComparePortrait';
import Register from 'components/Register';
import CookieConsent from 'react-cookie-consent';
import ConstantData from 'data/ConstantData';
import PoliciesCopyData from 'data/PoliciesData';
import PoliciesModal from 'components/PoliciesModal';
import {portraitMode} from 'util/helper';

const useStyles = createUseStyles(
    {
        root: (props) => ({
            // height: "100vh",
            height: '100%',
            color: props.ThemeData.colours.primaryFont,
            backgroundColor: props.ThemeData.colours.white,
            '--header-height': props.isPortraitMode ? '164px' : '100px',
            '--footer-height': '100px',
            '--portrait-header-height': '167px',
            '--navigation-height': '108px',
            '--app-height': '100%',
            '--small-widget-height': '586px',
            '--content-pane-height': '662px',
            '--media-pane-height': '958px',
            '--accessibility-footer':
                props.isPortraitMode && props.showAccessibilityFooter ? '100px' : '0px',
        }),
        cookieContainer: {
            border: '1px solid rgba(238, 237, 230, 0.5)',
            margin: '0 0 2% 2%',
            textAlign: 'left',
            padding: '0px 40px 0px 40px',
        },
        cookieContent: {
            fontSize: 16,
            height: 100,
            fontWeight: 100,
        },
        cookieButton: {
            background: 'rgb(255,255,255) !important',
            color: 'black',
            height: 60,
            width: 195,
        },
        cookieDeclineButton: {
            display: 'none',
        },
        cookieButtonWrapper: {
            position: 'absolute',
            top: 160,
        },
        continueAnywaysButton: (props) => ({
            marginTop: 60,
            cursor: 'pointer',
            fontSize: 12,
            color: props.ThemeData.colours.primaryAccent,
            fontFamily: props.ThemeData.fonts.title,
        }),
        optimalViewingDialog: {
            margin: 30,
            textAlign: 'center',
            textTransform: 'uppercase',
            letterSpacing: '0.1em',
            lineHeight: '30px',
        },
        optimalViewingTitle: (props) => ({
            fontSize: 20,
            color: props.ThemeData.colours.white,
            fontFamily: props.ThemeData.fonts.title,
        }),
        optimalViewingText: (props) => ({
            margin: 20,
            fontSize: 10,
            color: props.ThemeData.colours.white,
            fontFamily: props.ThemeData.fonts.copy,
        }),
        optimalViewingDialogContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100vw',
            backgroundColor: (props) => props.ThemeData.colours.primaryBg,
        },
        preloadWrapper: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#242037',
            height: '100%',
            color: '#fff',
            fontSize: 14,
            gap: 10,

            '& .proximaLogo': {
                backgroundImage: `url(${proximaIcon})`,
                backgroundSize: '70px 70px',
                height: 70,
                width: 70,
            },
        },
        loading: {
            display: 'flex',
            flexDirection: 'row',
            gap: 10,
            alignItems: 'center',
        },
    },
    {name: 'Root'},
);

function Root({project}) {
    const location = useLocation();
    const isPortraitMode = portraitMode();

    const {accessibility: showAccessibilityFooter} = accessibilityPane();
    const isBigScreenWidth = useMediaQuery({query: '(min-width: 3800px)'});
    const isBigScreenHeight = useMediaQuery({query: '(min-height: 3800px)'});
    const isTouchScreen = ('ontouchstart' in window) ||
        (navigator.maxTouchPoints > 0) ||
        (navigator.msMaxTouchPoints > 0);
    // const isTablet = useMediaQuery({query: '(min-width: 768px) and (max-width: 1024px)'})
    const [currentActiveFilters, setCurrentActiveFilters] = useState();
    const [filteredUnitsDataContext, setFilteredUnitsDataContext] = useState(0);
    const [currentSectionSlug, setCurrentSectionSlug] = useState(0);
    let isBigScreen = false;
    if (isBigScreenWidth || isBigScreenHeight) {
        isBigScreen = true;
    }

    const classes = useStyles({
        ThemeData: project.ThemeData,
        isPortraitMode,
        showAccessibilityFooter,
    });

    const isOptimalViewing = useMediaQuery({query: '(min-width: 600px)'});

    const proxima = useRef(null);
    const proximaContainer = useRef(null);
    const [isProximaLoaded, setIsProximaLoaded] = useState(false);

    const [filtredData, setFiltredData] = useState([]);

    const [selectedUnitId, setSelectedUnitId] = useState(-1);
    const [selectedFloorplanId, setSelectedFloorplanId] = useState(-1);

    const [interactModalFrom, setInteractModalFrom] = useState('');

    const [isOpenNavBar, setIsOpenNavBar] = useState(false);
    const [savedHomesCount, setSavedHomesCount] = useState(0);
    const [isSavedHomesSlideoutOpen, setIsSavedHomesSlideoutOpen] =
        useState(false);
    const toggleIsSavedHomesSlideoutOpen = () =>
        setIsSavedHomesSlideoutOpen(!isSavedHomesSlideoutOpen);
    const [isSavedHomesCompareOpen, setIsSavedHomesCompareOpen] = useState(false);
    const toggleIsSavedHomesCompareOpen = () =>
        setIsSavedHomesCompareOpen(!isSavedHomesCompareOpen);
    const [isSavedHomesComparePortraitOpen, setIsSavedHomesComparePortraitOpen] =
        useState(false);
    const toggleIsSavedHomesComparePortraitOpen = () =>
        setIsSavedHomesComparePortraitOpen(!isSavedHomesComparePortraitOpen);

    const [isSavedHomesCompareListOpen, setIsSavedHomesCompareListOpen] =
        useState(false);

    const [isRegisterOpen, setIsRegisterOpen] = useState(false);
    const toggleIsRegisterOpen = () => setIsRegisterOpen(!isRegisterOpen);
    const [isSendToEmailOpen, setIsSendToEmailOpen] = useState(false);
    const toggleIsSendToEmailOpen = () =>
        setIsSendToEmailOpen(!isSendToEmailOpen);
    const [prevSection, setPrevSection] = useState();
    const setPrevSectionFunction = (value) => setPrevSection(value);

    const [isSaveHomePortrait, setIsSaveHomePortrait] = useState(false);
    const [isSaveHomesInteractive, setIsSaveHomesInteractive] = useState(false);
    const toggleSetIsSaveHomesInteractive = (val) =>
        setIsSaveHomesInteractive(val);
    const [isCompareHomesInteractive, setIsCompareHomesInteractive] =
        useState(false);
    const toggleSetIsCompareHomesInteractive = (val) =>
        setIsCompareHomesInteractive(val);
    const [isContentPageCarousel, setIsContentPageCarousel] = useState(false);
    const [isContentPageCarouselOpen, setIsContentPageCarouselOpen] =
        useState(false);

    const [isSiteMapPage, setIsSiteMapPage] = useState(false);

    const unitDetailRef = useRef(null);
    let confirmFormRef = useRef(null);

    const {isUnitDetailOpen, setIsUnitDetailOpen, toggleIsUnitDetailOpen} =
        useOpenClosePane('UnitDetail');
    useEffect(() => {
        console.log('[isUnitDetailOpen]', isUnitDetailOpen);
    }, [isUnitDetailOpen]);
    const {isPrivacyPolicyDialogOpen, setIsPrivacyPolicyDialogOpenToClosed} =
        useOpenClosePane('PrivacyPolicyDialog');

    const {setIsCookiePolicyDialogOpenToOpen} =
        useOpenClosePane('CookiePolicyDialog');

    const unitFcns = {
        isBigScreen,
        isTouchScreen,
        selectedUnitId,
        setSelectedUnitId,
        setSelectedFloorplanId,
        selectedFloorplanId,
        isUnitDetailOpen,
        setIsUnitDetailOpen,
        toggleIsUnitDetailOpen,
        interactModalFrom,
        setInteractModalFrom,
        filtredData,
        setFiltredData,
        currentActiveFilters,
        setCurrentActiveFilters,
        setFilteredUnitsDataContext,
        filteredUnitsDataContext,
        setIsOpenNavBar,
        isOpenNavBar,
        setSavedHomesCount,
        savedHomesCount,
        setCurrentSectionSlug,
        currentSectionSlug,
        isSavedHomesSlideoutOpen,
    };

    const handlePrivacyPolicyClick = (e, openDialog) => {
        const el = e.target.closest('b');
        if (el && e.currentTarget.contains(el)) {
            setIsCookiePolicyDialogOpenToOpen();
        }
    };

    if (!isOptimalViewing) {
        return (
            <div className={classes.optimalViewingDialogContainer}>
                <div className={classes.optimalViewingDialog}>
                    <div className={classes.optimalViewingTitle}>
                        PLEASE OPEN ON DESKTOP
                    </div>
                    <div className={classes.optimalViewingText}>
                        To ensure the best experience with our application, please
                        access it using a desktop computer. Currently, our app is not
                        compatible with mobile devices, and as such, it cannot be
                        operated effectively or accessed via a smartphone or tablet. For
                        optimal performance and full functionality, we strongly
                        recommend using a desktop browser. We are currently building the
                        best mobile version of Proxima possible so thank you for your
                        understanding and patience.
                    </div>
                </div>
            </div>
        );
    }

    return (
        <UnitFcnContext.Provider value={unitFcns}>
            <CookieConsent
                location="bottom"
                buttonText="I UNDERSTAND"
                cookieName="ProximaByLngCookies"
                flipButtons
                expires={7}
                hideOnAccept
                enableDeclineButton
                containerClasses={classes.cookieContainer}
                buttonWrapperClasses={classes.cookieButtonWrapper}
                contentClasses={classes.cookieContent}
                buttonClasses={classes.cookieButton}
                declineButtonClasses={classes.cookieDeclineButton}
                style={{
                    width: 645,
                    height: 350,
                    background: 'rgba(0, 0, 0, 1)',
                    border: '1px solid rgba(238, 237, 230, 0.5)',
                    margin: '0 0 2% 2%',
                }}
                buttonStyle={{
                    background: 'blue',
                }}>
                <p style={{fontSize: 20, fontWeight: 400}}>
                    Proxima uses cookies
                </p>
                {ConstantData.cookieCopy.cookie}
                <p
                    onClick={(e) => handlePrivacyPolicyClick(e)}
                    style={{
                        fontSize: 14,
                        fontWeight: 100,
                        position: 'absolute',
                        bottom: 30,
                    }}
                    dangerouslySetInnerHTML={{
                        __html: ReactDOMServer.renderToString(
                            ConstantData.cookieCopy.privacyPolicy,
                        ),
                    }}
                />
            </CookieConsent>
            <div className={`${classes.root}`}>
                <AnimatePresence exitBeforeEnter>
                    <Switch location={location} key={location.pathname}>
                        <Route path="/proximation">
                            {!isPortraitMode && (
                                <Proximation
                                    project={project}
                                    proxima={proxima}
                                    proximaContainer={proximaContainer}
                                    isProximaLoaded={isProximaLoaded}
                                    setIsProximaLoaded={setIsProximaLoaded}
                                    isUnitDetailOpen={isUnitDetailOpen}
                                    setUnitDetailOpen={setIsUnitDetailOpen}
                                    toggleIsSavedHomesSlideoutOpen={
                                        toggleIsSavedHomesSlideoutOpen
                                    }
                                    isSavedHomesSlideoutOpen={isSavedHomesSlideoutOpen}
                                    toggleIsSavedHomesCompareOpen={
                                        toggleIsSavedHomesCompareOpen
                                    }
                                    isSavedHomesCompareOpen={isSavedHomesCompareOpen}
                                    toggleIsRegisterOpen={toggleIsRegisterOpen}
                                    toggleIsSendToEmailOpen={toggleIsSendToEmailOpen}
                                    // {...unitFcns}
                                />
                            )}
                            {isPortraitMode && (
                                <PortraitProximation
                                    project={project}
                                    proxima={proxima}
                                    proximaContainer={proximaContainer}
                                    isProximaLoaded={isProximaLoaded}
                                    setIsProximaLoaded={setIsProximaLoaded}
                                    isUnitDetailOpen={isUnitDetailOpen}
                                    setUnitDetailOpen={setIsUnitDetailOpen}
                                    toggleIsSavedHomesSlideoutOpen={
                                        toggleIsSavedHomesSlideoutOpen
                                    }
                                    isSavedHomesSlideoutOpen={isSavedHomesSlideoutOpen}
                                    toggleIsSavedHomesCompareOpen={
                                        toggleIsSavedHomesCompareOpen
                                    }
                                    isSavedHomesCompareOpen={isSavedHomesCompareOpen}
                                    toggleIsRegisterOpen={toggleIsRegisterOpen}
                                    toggleIsSendToEmailOpen={toggleIsSendToEmailOpen}

                                    // {...unitFcns}
                                />
                            )}
                        </Route>
                        <Route path="/:slug">
                            <Section
                                project={project}
                                toggleIsSavedHomesCompareOpen={
                                    toggleIsSavedHomesCompareOpen
                                }
                                toggleIsSavedHomesSlideoutOpen={
                                    toggleIsSavedHomesSlideoutOpen
                                }
                                isSavedHomesCompareOpen={isSavedHomesCompareOpen}
                                isSavedHomesSlideoutOpen={isSavedHomesSlideoutOpen}
                                prevSection={prevSection}
                                setSelectedUnitId={setSelectedUnitId}
                                setIsContentPageCarousel={setIsContentPageCarousel}
                                setSelectedFloorplanId={setSelectedFloorplanId}
                                setPrevSectionFunction={setPrevSectionFunction}
                                toggleIsRegisterOpen={toggleIsRegisterOpen}
                                toggleIsSendToEmailOpen={toggleIsSendToEmailOpen}
                                setIsContentPageCarouselOpen={setIsContentPageCarouselOpen}
                                isSiteMapPage={isSiteMapPage}
                                setIsSiteMapPage={setIsSiteMapPage}
                                {...unitFcns}
                            />
                        </Route>
                        <Route path="/">
                            <Section
                                project={project}
                                toggleIsSavedHomesCompareOpen={
                                    toggleIsSavedHomesCompareOpen
                                }
                                toggleIsSavedHomesSlideoutOpen={
                                    toggleIsSavedHomesSlideoutOpen
                                }
                                isSavedHomesCompareOpen={isSavedHomesCompareOpen}
                                isSavedHomesSlideoutOpen={isSavedHomesSlideoutOpen}
                                prevSection={prevSection}
                                setSelectedUnitId={setSelectedUnitId}
                                setIsContentPageCarousel={setIsContentPageCarousel}
                                setIsContentPageCarouselOpen={setIsContentPageCarouselOpen}
                                setSelectedFloorplanId={setSelectedFloorplanId}
                                setPrevSectionFunction={setPrevSectionFunction}
                                toggleIsRegisterOpen={toggleIsRegisterOpen}
                                toggleIsSendToEmailOpen={toggleIsSendToEmailOpen}
                                sectionSlug={SlugData.HOME_SLUG_NAME}
                            />
                        </Route>
                        <Route path="/404.html">
                            <div>
                                <h2>404 Error</h2>
                                <p>The page you are trying to reach is not here.</p>
                            </div>
                        </Route>
                    </Switch>
                </AnimatePresence>
                {!isPortraitMode && (
                    <UnitDetail
                        id="unit-detail"
                        project={project}
                        unitId={selectedUnitId}
                        floorplanId={selectedFloorplanId}
                        isOpen={isUnitDetailOpen}
                        toggleUnitDetailFcn={() => toggleIsUnitDetailOpen()}
                        setSelectedUnitId={setSelectedUnitId}
                        setSelectedFloorplanId={selectedFloorplanId}
                        isSavedHomesCompareListOpen={isSavedHomesCompareListOpen}
                        isSavedHomesSlideoutOpen={isSavedHomesSlideoutOpen}
                        isSavedHomesCompareOpen={isSavedHomesCompareOpen}
                        isSavedHomesComparePortraitOpen={
                            isSavedHomesComparePortraitOpen
                        }
                        isSaveHomesInteractive={isSaveHomesInteractive}
                        isCompareHomesInteractive={isCompareHomesInteractive}
                        unitDetailRef={unitDetailRef}
                        isSiteMapPage={isSiteMapPage}
                        setIsSiteMapPage={setIsSiteMapPage}
                    />
                )}
                {isPortraitMode && (
                    <UnitDetailPortrait
                        id="unit-detail"
                        project={project}
                        unitId={selectedUnitId}
                        floorplanId={selectedFloorplanId}
                        isOpen={isUnitDetailOpen}
                        toggleUnitDetailFcn={() => toggleIsUnitDetailOpen()}
                        setSelectedUnitId={setSelectedUnitId}
                        setSelectedFloorplanId={selectedFloorplanId}
                        isSavedHomesCompareListOpen={isSavedHomesCompareListOpen}
                        isSavedHomesSlideoutOpen={isSavedHomesSlideoutOpen}
                        isSavedHomesCompareOpen={isSavedHomesCompareOpen}
                        isSavedHomesComparePortraitOpen={
                            isSavedHomesComparePortraitOpen
                        }
                        isSaveHomesInteractive={isSaveHomesInteractive}
                        isCompareHomesInteractive={isCompareHomesInteractive}
                        unitDetailRef={unitDetailRef}
                        toggleSetIsSaveHomesInteractive={(val) =>
                            toggleSetIsSaveHomesInteractive(val)
                        }
                        toggleSetIsCompareHomesInteractive={(val) =>
                            toggleSetIsCompareHomesInteractive(val)
                        }
                    />
                )}
                {isPortraitMode && (
                    <SavedHomesComparePortrait
                        project={project}
                        isUnitDetailOpen={isUnitDetailOpen}
                        isOpen={isSavedHomesComparePortraitOpen}
                        SavedHomesSlideout={() => toggleIsSavedHomesSlideoutOpen()}
                        toggleIsOpen={() => toggleIsSavedHomesComparePortraitOpen()}
                        toggleIsSavedHomesSlideoutOpen={() =>
                            toggleIsSavedHomesSlideoutOpen()
                        }
                        toggleIsRegisterOpen={toggleIsSendToEmailOpen}
                        toggleIsUnitDetailOpen={toggleIsUnitDetailOpen}
                        isSavedHomesCompareListOpen={isSavedHomesCompareListOpen}
                        setIsSavedHomesCompareListOpen={setIsSavedHomesCompareListOpen}
                        toggleUnitDetailOpen={() => toggleIsUnitDetailOpen()}
                        setSelectedUnitId={setSelectedUnitId}
                        setSelectedFloorplanId={setSelectedFloorplanId}
                        unitDetailRef={unitDetailRef}
                        confirmFormRef={confirmFormRef}
                        isContentPageCarousel={isContentPageCarousel}
                        setIsContentPageCarousel={setIsContentPageCarousel}
                        isContentPageCarouselOpen={isContentPageCarouselOpen}
                        setIsContentPageCarouselOpen={setIsContentPageCarouselOpen}
                        toggleSetIsCompareHomesInteractive={(val) =>
                            toggleSetIsCompareHomesInteractive(val)
                        }
                    />
                )}
            </div>
            <SavedHomesSlideout
                project={project}
                isOpen={isSavedHomesSlideoutOpen}
                toggleIsOpen={() => toggleIsSavedHomesSlideoutOpen()}
                toggleIsSavedHomesCompareOpen={() =>
                    toggleIsSavedHomesCompareOpen()
                }
                toggleIsSavedHomesComparePortraitOpen={() =>
                    toggleIsSavedHomesComparePortraitOpen()
                }
                toggleIsRegisterOpen={toggleIsSendToEmailOpen}
                toggleUnitDetailOpen={() => toggleIsUnitDetailOpen()}
                setSelectedUnitId={setSelectedUnitId}
                setSelectedFloorplanId={setSelectedFloorplanId}
                isSaveHomePortrait={isSaveHomePortrait}
                setIsSaveHomePortrait={setIsSaveHomePortrait}
                isSaveHomesInteractive={isSaveHomesInteractive}
                toggleSetIsSaveHomesInteractive={(val) =>
                    toggleSetIsSaveHomesInteractive(val)
                }
                isUnitDetailOpen={isUnitDetailOpen}
                unitDetailRef={unitDetailRef}
                confirmFormRef={confirmFormRef}
                isContentPageCarousel={isContentPageCarousel}
                setIsContentPageCarousel={setIsContentPageCarousel}
            />
            {!isPortraitMode && (
                <SavedHomesCompare
                    project={project}
                    isUnitDetailOpen={isUnitDetailOpen}
                    isOpen={isSavedHomesCompareOpen}
                    toggleIsOpen={() => toggleIsSavedHomesCompareOpen()}
                    toggleIsSavedHomesSlideoutOpen={() =>
                        toggleIsSavedHomesSlideoutOpen()
                    }
                    toggleIsRegisterOpen={toggleIsSendToEmailOpen}
                    toggleIsUnitDetailOpen={toggleIsUnitDetailOpen}
                    isSavedHomesCompareListOpen={isSavedHomesCompareListOpen}
                    setIsSavedHomesCompareListOpen={setIsSavedHomesCompareListOpen}
                    isSiteMapPage={isSiteMapPage}
                />
            )}

            <Register
                project={project}
                doShow={isRegisterOpen}
                toggleIsOpen={() => toggleIsRegisterOpen()}
                titleText={project && `Welcome ${project.name}`}
                buttonText="Let's get started"
            />
            <Register
                project={project}
                doShow={isSendToEmailOpen}
                toggleIsOpen={() => toggleIsSendToEmailOpen()}
                titleText="Send your favourite floorplans right to your inbox"
                buttonText="Send"
            />
            {/*FIXME
					<PoliciesModal
						data={data && data.global.cookiePolicy}
						isDialogOpen={isCookiePolicyDialogOpen}
						setIsDialogOpenToClosed={() => setIsCookiePolicyDialogOpenToClosed()}
						onClickFcn={() => setIsPrivacyPolicyDialogOpenToOpen()}
						/>*/}
            <PoliciesModal
                data={PoliciesCopyData.privacyPolicy}
                isDialogOpen={isPrivacyPolicyDialogOpen}
                setIsDialogOpenToClosed={setIsPrivacyPolicyDialogOpenToClosed}
            />
        </UnitFcnContext.Provider>
    );
}

export default Root;
