import { createContext } from "react";

const UnitFcnContext = createContext({
  selectedUnitId: null,
  setSelectedUnitId: null,
  selectedFloorplanId: null,
  setSelectedFloorplanId: null,
  interactModalFrom: "",
  setInteractModalFrom: null,

  isUnitDetailOpen: null,
  setIsUnitDetailOpen: null,
  toggleIsUnitDetailOpen: null,
  isBigScreen: false,
  isTouchScreen: false,
  filtredData: [],
  setFiltredData: null,
  currentActiveFilters: null,
  setCurrentActiveFilters: null,
  filteredUnitsDataContext: null,
  setFilteredUnitsDataContext: null,
  isOpenNavBar:false,
  setIsOpenNavBar:null,
  isSavedHomesSlideoutOpen: false,
});

export default UnitFcnContext;
