import React, { useContext, useEffect, useState } from "react";

import { createUseStyles } from "react-jss";

import { motion, AnimatePresence } from "framer-motion";

import arrowLineRight from "../img/arrow_line_right.svg";
import crossIcon from "../img/cross_icon.svg";
import { ReactComponent as FilterIcon } from "../img/filter.svg";
import { ReactComponent as ArrowDown } from "../img/arrow_down.svg";
import { InputAdornment } from "@mui/material";
import Box from "@mui/material/Box";
import useGlobalUnitsFilter from "hooks/useGlobalUnitsFilter";

import ButtonOutline from "./ButtonOutline";
import FilterRangeInput from "./FilterRangeInput";
import FilterButtonInput from "./FilterButtonInput";
import { filterableSources } from "data/FilterData";
import { useLocation } from "react-router-dom";
import { portraitMode } from "util/helper";
import UnitFcnContext from "contexts/UnitFcnContext";

const useStyles = createUseStyles(
  {
    modalContainer: {
      position: "fixed",
      backgroundColor: (props) =>
        props.isPortraitMode ? "#ffffff" : "rgba(0, 0, 0, 0.8)",
      width: "100%",
      height: "100vh",
      top: 0,
      display: "flex",
      color: "black",
      overflow: "hidden",
      zIndex: 9999,
      justifyContent: "center",
    },
    inlineContainer: {
      height: "100%",
    },
    borderWrapper: (props) => ({
      maxWidth: props.isPortraitMode ? "auto" : 800,
      margin: props.isPortraitMode ? 0 : "50px 0",
      background: "transparent",
      "&:hover": {
        background: "black",
      },
    }),
    wrapper: {
      height: "100%",
      textAlign: "left",
      background: (props) => props.ThemeData.colours.white,
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.33)",
      borderRadius: (props) => (props.isPortraitMode ? 20 : 5),
      overflow: "hidden",
      width: (props) => (props.isPortraitMode ? "auto" : 800),
    },
    header: (props) => ({
      display: "flex",
      width: "100%",
      height: props.portraitMode ? 80 : 60,
      border: props.isPortraitMode ? "0" : "1px solid #EEEDE6",
      alignItems: "center",
      color: props.isPortraitMode ? props.ThemeData.colours.white : "inherit",
      marginBottom: 15,
      "& > img": {
        alignSelf: "flex-end",
        height: 14,
        width: 14,
        marginRight: 28,
        margin: "auto 0",
        cursor: "pointer",
        display: props.isPortraitMode ? "none" : "static",
      },
      "& > div:first-child": {
        alignSelf: "center",
        flexGrow: "3",
        textAlign: "center",
        paddingLeft: 42,
      },
    }),

    showHideWrapper: (props) => ({
      padding: "20px 40px",
      height: props.isPortraitMode
        ? "calc(var(--content-pane-height) - var(--footer-height) - 125px)"
        : "calc(100% - 170px)",
      "@media screen and (min-height: 1921px) and (max-height: 2000px)": {
        height: props.isPortraitMode
          ? "calc(var(--content-pane-height) - var(--footer-height) - 100px )"
          : "calc(100% - 170px)",
      },
      "@media screen and (min-height: 2000px) and (max-height: 2049px)": {
        height: props.isPortraitMode
          ? "calc(var(--content-pane-height) - var(--footer-height) - 50px )"
          : "calc(100% - 170px)",
      },
      "@media screen and (min-height: 2050px)": {
        height: props.isPortraitMode
          ? "calc(var(--content-pane-height) - var(--footer-height) )"
          : "calc(100% - 170px)",
      },
      overflow: "scroll",
      "&::-webkit-scrollbar": {
        // display: 'none'
        width: "5px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor:
          "rgba(0, 0, 0, 0.5)" /* Color of the scrollbar thumb */,
        borderRadius: "5px" /* Border radius of the scrollbar thumb */,
      },
      // scrollbarWidth: 'none',
    }),

    expandRetractButtonWrapper: {
      position: "absolute",
      top: 0,
      right: 0,
    },
    expandRetractButton: {
      width: 75,
      height: 75,

      background: "no-repeat center",
      backgroundImage: `url( ${arrowLineRight} )`,

      "&.isToExpand": {
        width: 110,
      },
    },
    filterTitle: {
      display: "flex",
      textTransform: "uppercase",
      color: (props) => props.ThemeData.colours.vibrantDarkBlue,
      fontSize: 12,
      fontWeight: 700,
      height: 44,
      alignItems: "center",
      justifyContent: "center",
      gap: 11,
      borderBottom: (props) => `1px solid ${props.ThemeData.colours.lightTan}`,
    },

    paneTitle: {
      width: 250,

      "& h3": {
        margin: 0,

        fontFamily: (props) => props.ThemeData.fonts.titleSub,
        fontSize: "24px",
        lineHeight: "32px",
        fontWeight: "350",
        letterSpacing: ".05em",

        textTransform: "uppercase",
      },
    },
    typeTitle: {
      textTransform: "uppercase",
      fontWeight: 700,
    },
    filterBy: {
      fontFamily: (props) => props.ThemeData.fonts.copy,
      fontSize: 16,
      letterSpacing: ".05em",

      "& input": {
        display: "inline-block",

        margin: 10,
      },
    },
    filterRange: {
      "& > div": {
        width: "100%",
      },
      "&.inputBox": {
        height: 53,
      },
    },
    filterActivated: (props) => ({
      // backgroundColor: props.ThemeData.colours.primaryAccentFade,
      color: props.ThemeData.colours.primaryAccent,
      border: `1px solid ${props.ThemeData.colours.primaryAccent}`,
    }),
    filterSection: {
      marginTop: 30,
      borderBottom: (props) => `1px solid ${props.ThemeData.colours.lightTan}`,
      paddingBottom: 20,
    },
    filterButtons: {
      display: "flex",
      flexWrap: "wrap",
      "& > div": {
        flex: "0 1 auto",
      },
    },
    filterSlider: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      "& > div": {
        flex: "0 1 auto",
        width: "100%",
      },
    },
    filterItem: {
      minWidth: 30,
      height: 40,
      margin: 5,
      padding: "0 8px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: `1px #EEEDE6 solid`,
      borderRadius: 4,
      transition: "background .25s ease-out",
      cursor: "pointer",
      "&:hover": (props) => ({
        backgroundColor: props.ThemeData.colours.primaryAccentFade,
        color: props.ThemeData.colours.primaryAccent,
      }),
    },
    enjoyViewButtonWrapper: {
      display: "table",
    },
    closeFilter: (props) => ({
      borderRadius: 15,

      "&:hover": {
        "& svg": {
          stroke: props.ThemeData.colours.white,
        },
      },
    }),
    row: {
      display: "flex",
      flexDirection: "row",
      "& > div": {
        width: "100%",
      },
      "& > div:last-child": {
        // borderLeft: props => `1px solid ${props.ThemeData.colours.lightTan}`,
        paddingLeft: 20,
      },
    },
  },
  {
    name: "FilterPane",
  }
);

const FilterPaneRender = (props) => {
  const {
    project,
    project: {
      filterData,
      ThemeData,
      settings: { showUnits, showPrice },
      filterSettings: { unitFilter: unitFilterSettings },
    },
    classes,
    doShowFilter,
    setDoShowFilter,
    hooks,
    modelType,
    interactModalFrom,
    isPortraitMode,
  } = props;

  const [isClearSlider, setClearSlider] = useState(false);
  const [unitFilters, setUnitFilters] = useState({
    filter: isPortraitMode
      ? hooks.globalUnitsFilter.globalUnitsFilter.filter
      : {},
  });

  const { setFiltredData } = useContext(UnitFcnContext);

  // const isEmpty = obj => obj && Object.keys(obj).length === 0;
  // if (!isEmpty(unitFilters.filter)) {
  //   arr = Object.values(unitFilters.filter);
  // }
  useEffect(() => {
    let arr = [];

    if (unitFilters?.filter !== undefined) {
      arr = Object.values(unitFilters);
    }

    setFiltredData(arr);
  }, [unitFilters]);

  const setFilter = (_filter) =>
    setUnitFilters({ ...unitFilters, filter: _filter });

  const resetFilter = () => {
    setUnitFilters({ ...unitFilters, filter: {} });
    hooks.globalUnitsFilter.resetFilter();
    if (hooks.setSelectedUnitId) hooks.setSelectedUnitId(-1);
    setDoShowFilter(false);
  };

  const closeDialog = () => {
    setUnitFilters({
      filter: hooks.globalUnitsFilter.globalUnitsFilter.filter,
    });
    setDoShowFilter(false);
  };

  useEffect(() => {
    setClearSlider(false);
    if (
      hooks.globalUnitsFilter.globalUnitsFilter &&
      hooks.globalUnitsFilter.globalUnitsFilter.filter &&
      Object.keys(hooks.globalUnitsFilter.globalUnitsFilter.filter).length === 0
    ) {
      setUnitFilters({ filter: {} });
    }
  }, [isClearSlider, hooks.globalUnitsFilter.globalUnitsFilter.filter]);

  const location = useLocation();
  const isModelPage = location.pathname.includes("/proximation") ? true : false;
  const useUnitFilters = isModelPage || showUnits || props.isSiteMapPage;

  const availabilityStatuses = useUnitFilters
    ? filterData.unitAvailabilityStates
    : filterData.floorplanAvailabilityStates;
  const categoryStatues = useUnitFilters
    ? filterData.unitCategoryStates
    : filterData.floorplanCategoryStates;

  let unitFloors = filterData.unitFloors;
  const unitBuildings = unitFilters.filter["unitBuildings"];
  if (
    showUnits &&
    filterData.unitFloors.length > 0 &&
    unitBuildings &&
    Object.keys(unitBuildings).length > 0
  ) {
    const selectedBuildingIds = Object.keys(unitBuildings)
      .filter((id) => unitBuildings[id].doFilterByOr)
      .map((id) => id);
    if (selectedBuildingIds.length > 0) {
      unitFloors = filterData.unitFloors.filter((floor) => {
        const matches = floor.buildings.filter((id) =>
          selectedBuildingIds.includes(id)
        );
        return matches.length > 0;
      });
    }
  }
  const buildingType =
    props?.isSiteMapPage && props?.buildingId
      ? project.buildings.filter((b) => b.id === props?.buildingId)[0].type
      : "mixedType";
  let floorPlans = props?.buildingId
    ? filterData.floorPlans.filter(
        (f) =>
          f.buildingId === props?.buildingId &&
          (buildingType === "mixedType" || f.floorplanUnitType === buildingType)
      )
    : filterData.floorPlans;

  if (filterData.floorPlans.length > 1) {
    floorPlans = props?.buildingId
      ? filterData.floorPlans.filter(
          (f) =>
            f.buildingId === props?.buildingId &&
            (buildingType === "mixedType" ||
              f.floorplanUnitType === buildingType)
        )
      : filterData.floorPlans;
    // floorPlans = sortDataByName(floorPlans);
  }

  if (unitFloors?.length > 1) {
    unitFloors.sort((a, b) => a.id - b.id);
  }

  return (
    doShowFilter && (
      <div
        className={
          isPortraitMode ? classes.inlineContainer : classes.modalContainer
        }
      >
        <motion.div className={classes.borderWrapper}>
          {isPortraitMode && (
            <div className={classes.header}>
              <div>FILTERS</div>
              <ButtonOutline
                ThemeData={ThemeData}
                key="closefilter"
                padding={16}
                borderColour={
                  ThemeData.colours.filterColourPortrait
                    ? ThemeData.colours.filterColourPortrait
                    : ThemeData.colours.vibrantGreen
                }
                textColour={
                  ThemeData.colours.filterColourPortrait
                    ? ThemeData.colours.filterColourPortrait
                    : ThemeData.colours.primaryAccent
                }
                hoverTextColour={ThemeData.colours.white}
                backgroundColour={ThemeData.colours.white}
                hoverBackgroundColour={ThemeData.colours.primaryAccent}
                className={classes.closeFilter}
                onClick={() => setDoShowFilter(false)}
                textTransform={"capitalized"}
              >
                <ArrowDown
                  fill={
                    ThemeData.colours.filterColourPortrait
                      ? ThemeData.colours.filterColourPortrait
                      : ThemeData.colours.vibrantGreen
                  }
                  style={{ marginRight: 10 }}
                />
                Close Filters
              </ButtonOutline>
            </div>
          )}
          <div className={classes.wrapper}>
            <AnimatePresence>
              {!isPortraitMode && (
                <div className={classes.header}>
                  <div>FILTERS</div>
                  <img
                    onClick={() => closeDialog()}
                    src={crossIcon}
                    alt="close"
                  />
                </div>
              )}
              {isPortraitMode && (
                <div className={classes.filterTitle}>
                  <FilterIcon stroke={ThemeData.colours.vibrantGreen} />
                  <div>Select Filters to improve your seatch</div>
                </div>
              )}
              <div className={classes.showHideWrapper}>
                <div className={classes.row}>
                  {unitFilterSettings?.availability?.enable &&
                    availabilityStatuses &&
                    availabilityStatuses.length > 1 && (
                      <div className={`${classes.filterSection}`}>
                        <div className={classes.rowItem}>
                          <div className={classes.typeTitle}>
                            <p>
                              {unitFilterSettings.availability.name ||
                                "Availability"}
                              :
                            </p>
                          </div>
                          <div className={classes.filterButtons}>
                            {availabilityStatuses.map((availabilityState) => (
                              <FilterButtonInput
                                ThemeData={ThemeData}
                                key={`availabilityState-${availabilityState.id}`}
                                classes={classes}
                                name={availabilityState.name}
                                filterObject={availabilityState}
                                data={filterData}
                                listItemId={availabilityState.id}
                                filterType={
                                  isModelPage
                                    ? filterableSources.availability
                                        .unitRelationDataType
                                    : filterableSources.floorplanAvailability
                                        .unitRelationDataType
                                }
                                filterSettings={unitFilters.filter}
                                modelType={modelType}
                                setFilter={setFilter}
                              />
                            ))}
                          </div>
                        </div>
                      </div>
                    )}

                  {unitFilterSettings?.orientation?.enable &&
                    useUnitFilters &&
                    filterData.unitExposures &&
                    filterData.unitExposures.length > 1 && (
                      <div
                        className={`${classes.filterSection} ${classes.orientationTypeWrapper}`}
                      >
                        <div className={classes.rowItem}>
                          <div className={classes.typeTitle}>
                            <p>
                              {unitFilterSettings.orientation.name ||
                                "Orientation"}
                            </p>
                          </div>
                          <div className={classes.filterButtons}>
                            {filterData.unitExposures.map((exposure) => (
                              <FilterButtonInput
                                key={`exposure-${exposure.id}`}
                                classes={classes}
                                name={exposure.direction}
                                filterObject={exposure}
                                data={filterData}
                                hooks={hooks}
                                listItemId={exposure.id}
                                filterType={
                                  filterableSources.orientation
                                    .unitRelationDataType
                                }
                                // listItemId={floorplan.id}
                                // listItemName={floorplan.name}
                                // filterType={filterableSources.floorPlans.unitRelationDataType}
                                filterSettings={unitFilters.filter}
                                modelType={modelType}
                                setFilter={setFilter}
                              />
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                </div>

                <div
                  className={`${
                    unitFilterSettings?.price?.enable &&
                    showPrice &&
                    unitFilterSettings?.size?.enable
                      ? classes.row
                      : ""
                  }`}
                >
                  {unitFilterSettings?.price?.enable && showPrice && (
                    <div
                      className={`${classes.filterSection} ${classes.orientationTypeWrapper}`}
                    >
                      <div className={classes.typeTitle}>
                        <p>{unitFilterSettings.price.name || "Price"}</p>
                      </div>
                      <div className={classes.filterSlider}>
                        <FilterRangeInput
                          ThemeData={ThemeData}
                          key="price-rangeSlider"
                          className={classes.filterRange}
                          label={filterableSources.price.label}
                          filterType={filterableSources.price.unitField}
                          min={
                            unitFilterSettings.price.min ||
                            filterableSources.price.rangeMinimumValue
                          }
                          max={
                            unitFilterSettings.price.max ||
                            filterableSources.price.rangeMaximumValue
                          }
                          step={filterableSources.price.rangeStep}
                          displayedDataFormattingFunction={
                            filterableSources.price
                              .displayedDataFormattingFunction
                          }
                          filterSettings={unitFilters.filter}
                          modelType={modelType}
                          inputLabels={["min", "max"]}
                          isClear={isClearSlider}
                          setFilter={setFilter}
                        />
                      </div>
                    </div>
                  )}

                  {unitFilterSettings?.size?.enable && (
                    <div
                      className={`${classes.filterSection} ${classes.orientationTypeWrapper}`}
                    >
                      <div className={classes.typeTitle}>
                        <p>{unitFilterSettings.size.name || "Size"}</p>
                      </div>
                      <div className={classes.filterSlider}>
                        <FilterRangeInput
                          ThemeData={ThemeData}
                          key="size-rangeSlider"
                          className={classes.filterRange}
                          label={filterableSources.size.label}
                          filterType={filterableSources.size.unitField}
                          min={
                            unitFilterSettings.size.min ||
                            filterableSources.size.rangeMinimumValue
                          }
                          max={
                            unitFilterSettings.size.max ||
                            filterableSources.size.rangeMaximumValue
                          }
                          step={filterableSources.size.rangeStep}
                          displayedDataFormattingFunction={
                            filterableSources.size
                              .displayedDataFormattingFunction
                          }
                          filterSettings={unitFilters.filter}
                          modelType={modelType}
                          inputLabels={["min", "max"]}
                          isClear={isClearSlider}
                          setFilter={setFilter}
                        />
                      </div>
                    </div>
                  )}
                </div>

                {unitFilterSettings?.floorPlans?.enable &&
                  filterData.floorPlans &&
                  filterData.floorPlans.length > 1 && (
                    <div
                      className={`${classes.filterSection} ${classes.homeTypeWrapper} `}
                    >
                      <div className={classes.typeTitle}>
                        <p>
                          {unitFilterSettings.floorPlans.name || "Floor Plans"}
                        </p>
                      </div>
                      <div className={classes.filterButtons}>
                        {floorPlans.map((floorplan) => (
                          <FilterButtonInput
                            ThemeData={ThemeData}
                            key={`floorplan-${floorplan.id}`}
                            classes={classes}
                            name={floorplan.name}
                            filterObject={floorplan}
                            data={filterData}
                            listItemId={floorplan.id}
                            listItemName={floorplan.name}
                            filterType={
                              filterableSources.floorPlans.unitRelationDataType
                            }
                            filterSettings={unitFilters.filter}
                            modelType={modelType}
                            setFilter={setFilter}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                {project.projectType.includes("industrial") &&
                  unitFilterSettings?.floorPlanType?.enable &&
                  filterData.floorplanCategoryStates &&
                  filterData.floorplanCategoryStates.length > 1 && (
                    <div
                      className={`${classes.filterSection} ${classes.homeTypeWrapper} `}
                    >
                      <div className={classes.typeTitle}>
                        <p>
                          {unitFilterSettings.floorPlanType.name ||
                            "Floor Plan Categories"}
                        </p>
                      </div>
                      <div className={classes.filterButtons}>
                        {categoryStatues.map((category) => (
                          <>
                            <FilterButtonInput
                              ThemeData={ThemeData}
                              key={`availabilityState-${category.id}`}
                              classes={classes}
                              name={category.name}
                              filterObject={category}
                              data={filterData}
                              listItemId={category.id}
                              filterType={
                                useUnitFilters
                                  ? filterableSources.categories
                                      .unitRelationDataType
                                  : filterableSources.floorplanCategories
                                      .unitRelationDataType
                              }
                              // filterType={filterableSources.floorplanCategories.unitRelationDataType}
                              filterSettings={unitFilters.filter}
                              modelType={modelType}
                              setFilter={setFilter}
                            />
                          </>
                        ))}
                      </div>
                    </div>
                  )}

                {unitFilterSettings?.rooms?.enable &&
                  filterData.unitHomeTypes &&
                  filterData.unitHomeTypes.length > 1 && (
                    <div
                      className={`${classes.filterSection} ${classes.homeTypeWrapper} `}
                    >
                      <div className={classes.typeTitle}>
                        <p>{unitFilterSettings.rooms.name || "Unit Types"}</p>
                      </div>
                      <div className={classes.filterButtons}>
                        {filterData.unitHomeTypes.map((homeType) => (
                          <FilterButtonInput
                            ThemeData={ThemeData}
                            key={`homeType-${homeType.id}`}
                            classes={classes}
                            name={homeType.name}
                            filterObject={homeType}
                            data={filterData}
                            //hooks={hooks}
                            listItemId={homeType.id}
                            filterType={
                              filterableSources.rooms.unitRelationDataType
                            }
                            filterSettings={unitFilters.filter}
                            modelType={modelType}
                            setFilter={setFilter}
                          />
                        ))}
                      </div>
                    </div>
                  )}

                {unitFilterSettings?.buildings?.enable &&
                  filterData.unitBuildings &&
                  filterData.unitBuildings.length > 1 && (
                    <div
                      className={`${classes.filterSection} ${classes.homeTypeWrapper} `}
                    >
                      <div className={classes.typeTitle}>
                        <p>
                          {unitFilterSettings.buildings.name || "Buildings"}
                        </p>
                      </div>
                      <div className={classes.filterButtons}>
                        {filterData.unitBuildings.map((building) => (
                          <FilterButtonInput
                            key={`building-${building.id}`}
                            classes={classes}
                            name={building.name}
                            filterObject={building}
                            data={filterData}
                            hooks={hooks}
                            listItemId={building.id}
                            filterType={
                              filterableSources.buildings.unitRelationDataType
                            }
                            filterSettings={unitFilters.filter}
                            setFilter={setFilter}
                          />
                        ))}
                      </div>
                    </div>
                  )}

                {unitFilterSettings?.floors?.enable &&
                  useUnitFilters &&
                  unitFloors &&
                  unitFloors.length > 1 && (
                    <div
                      className={`${classes.filterSection} ${classes.homeTypeWrapper} `}
                    >
                      <div className={classes.typeTitle}>
                        <p>{unitFilterSettings.floors.name || "Floors"}</p>
                      </div>
                      <div className={classes.filterButtons}>
                        {unitFloors.map((floor) => {
                          return (
                            <FilterButtonInput
                              key={`building-${floor.id}`}
                              classes={classes}
                              name={floor.name}
                              filterObject={floor}
                              data={filterData}
                              hooks={hooks}
                              listItemId={floor.id}
                              filterType={
                                filterableSources.floors.unitRelationDataType
                              }
                              filterSettings={unitFilters.filter}
                              setFilter={setFilter}
                            />
                          );
                        })}
                      </div>
                    </div>
                  )}
              </div>
              <Box
                key="box-buttons"
                sx={{
                  width: "100%",
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                }}
              >
                <div className={classes.moreDetailsButtonArea}>
                  <ButtonOutline
                    ThemeData={ThemeData}
                    key="clearall"
                    label="Clear All"
                    padding={20}
                    textColour={
                      ThemeData.colours.filterColourPortrait
                        ? ThemeData.colours.filterColourPortrait
                        : ThemeData.colours.primaryAccent
                    }
                    hoverTextColour={ThemeData.colours.white}
                    borderColour={ThemeData.colours.white}
                    backgroundColour={ThemeData.colours.clay05}
                    onClick={() => {
                      resetFilter();
                      setClearSlider(true);
                    }}
                  />
                </div>
                <div className={classes.moreDetailsButtonArea}>
                  <ButtonOutline
                    ThemeData={ThemeData}
                    key="apply"
                    label="Apply"
                    padding={20}
                    textColour={
                      ThemeData.colours.filterColourPortrait
                        ? ThemeData.colours.filterColourPortrait
                        : ThemeData.colours.primaryAccent
                    }
                    hoverTextColour={ThemeData.colours.white}
                    border="none"
                    backgroundColour={ThemeData.colours.clay05}
                    onClick={() => {
                      setDoShowFilter(false);
                      if (
                        hooks.setInteractModalFrom &&
                        interactModalFrom !== "filter"
                      )
                        hooks.setInteractModalFrom("filter");
                      hooks.globalUnitsFilter.setFilter(unitFilters.filter);
                    }}
                  />
                </div>
              </Box>
            </AnimatePresence>
          </div>
        </motion.div>
      </div>
    )
  );
};

const FilterPane = (props) => {
  const { project, setInteractModalFrom, setSelectedUnitId } = props;
  const globalUnitsFilter = useGlobalUnitsFilter();
  const isPortraitMode = portraitMode();

  const hooks = {
    globalUnitsFilter,
    setInteractModalFrom,
    setSelectedUnitId,
  };
  const classes = useStyles({ ThemeData: project.ThemeData, isPortraitMode });
  return FilterPaneRender({ ...props, classes, hooks, isPortraitMode });
};

export default FilterPane;
