import React, {useContext, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {createUseStyles} from 'react-jss';
import {motion} from 'framer-motion';
import useSavedHomes from 'hooks/useSavedHomes';
import ButtonGroup from './ButtonGroup';
import ButtonOutline from './ButtonOutline';
import UnitInfoPlanNameTitle from 'components/UnitInfoPlanNameTitle';
import UnitInfoLine from './UnitInfoLine';
import {sendGoogleAnalyticsEvent} from 'util/sendGoogleAnalyticsEvent';
import NetData from '../data/NetData';
import {BookMarkButton} from './BookMarkButton';
import {getImageBySize} from 'util/imgFunctions';
import {portraitMode, getStatusClass, isAvailableStatus} from 'util/helper';
import useCompareHomes from 'hooks/useCompareHomes';
import DownloadIcon from '../img/Download.svg';
import MailIcon from '../img/Mail.svg';
import FormModal from "./FormModal";
import UnitFcnContext from "../contexts/UnitFcnContext";

const useStyles = createUseStyles(
    {
        wrapper: (props) => ({
            opacity: props.isAvailable ? 1 : 0.5,
            position: 'relative',
            paddingTop: 5,
            boxSizing: 'border-box',
            ...props.ThemeData.styles.hoverCard,
            boxShadow: props.isPortraitMode
                ? '0px 2px 5px 0px rgba(0, 0, 0, 0.25)'
                : '0px 4px 10px rgb(0 0 0 / 33%)',
            borderRadius: props.isPortraitMode ? (props.twoTile ? 15 : 20) : 5,

            height: props.isPortraitMode
                ? props.calledByFloorplan
                    ? 524
                    : props.twoTile
                        ? 290
                        : props.calledByCarousel
                            ? 'calc(100% - var(--accessibility-footer))'
                            : '300px'
                : 'auto',

            width: props.isPortraitMode
                ? props.calledByFloorplan
                    ? 458
                    : props.twoTile
                        ? 215
                        : props.calledByCarousel
                            ? 'calc(780px - var(--accessibility-footer))'
                            : 'auto'
                : 'auto',
        }),
        selectedUnitHighlight: {
            border: (props) => `2px ${props.ThemeData.colours.primaryAccent} solid`,
        },
        hidden: {
            display: 'none',
        },
        floorplanImagePortrait: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: (props) =>
                props.calledByFloorplan
                    ? 323
                    : props.twoTile
                        ? 142
                        : props.calledByCarousel
                            ? 430
                            : 'auto',
            '& img': {
                width: '100%',
                maxWidth: (props) =>
                    props.calledByFloorplan ? 400 : props.calledByCarousel ? 650 : 190,
                maxHeight: (props) =>
                    props.calledByFloorplan ? 300 : props.calledByCarousel ? 430 : '98%',
            },
        },
        floorplanImage: {
            borderBottom: (props) => `1px ${props.ThemeData.colours.blackBg15} solid`,
            height: 'auto',
            width: (props) => (props.calledByFloorplan ? 'auto' : 350),
            display: 'flex',
            justifyContent: 'center',
            '& img': {
                width: '100%',
                height: 255,
                maxWidth: 260,
                maxHeight: 'auto',
            },
        },
        '$floorplanImage, $details': {
            borderBottom: (props) =>
                `1px ${props.ThemeData.colours.mainBorder} solid`,
        },
        details: (props) => ({
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            position: 'relative',
            minHeight: props.twoTile ? 'auto' : 63,
            height: props.isPortraitMode
                ? props.calledByFloorplan
                    ? 100
                    : props.twoTile
                        ? 71
                        : props.calledByCarousel
                            ? 135
                            : 'auto'
                : 'auto',
            padding: props.twoTile
                ? '0px 13px 0px 13px'
                : props.calledByCarousel
                    ? '20px 35px'
                    : '5px 20px 20px 20px',
            textAlign: 'left',
        }),
        unitInfoPlanNameTitle: (props) => ({
            margin: 0,
            padding: 0,
            color: props.ThemeData.colours.secondaryFont,
            fontFamily: props.ThemeData.fonts.title,
            fontSize:
                props.calledByCarousel || props.calledByFloorplan
                    ? props.isPortraitMode
                        ? 24
                        : 16
                    : props.isPortraitMode
                        ? 14
                        : 16,
        }),
        unitInfoLine: (props) => ({
            marginTop: props.calledByFloorplan
                ? 20
                : props.calledByCarousel
                    ? 30
                    : 10,
            color: props.ThemeData.colours.grey,
            fontFamily: props.ThemeData.fonts.titleSub,
            fontSize:
                props.calledByCarousel || props.calledByFloorplan
                    ? props.isPortraitMode
                        ? 16
                        : 12
                    : 12,
        }),
        actionButton: {
            position: 'absolute',
            top: 16,
            right: 20,
            fontSize: 33,
            lineHeight: '32px',
        },
        buttonArea: {
            paddingInline: 20,
            paddingBlockEnd: (props) => (props.twoTile ? 10 : 20),
        },
        moreDetailsButtonArea: (props) => ({
            position: props.isPortraitMode ? 'absolute' : 'relative',
            width: props.isPortraitMode ? '100%' : 'auto',
            bottom: props.isPortraitMode ? 0 : '',
            borderTop: `1px ${props.ThemeData.colours.vibrantGreen} solid`,
        }),
        buttonOutline: (props) => ({
            cursor: props.isAvailable ? 'pointer' : 'not-allowed',
            padding: props.twoTile ? 10 : '15px 10px',
            fontSize: props.twoTile ? 8 : 10,
            fontWeight: 700,
            borderRadius: 5,
        }),
        buttonGroup: {
            '& > div': {
                marginLeft: (props) => (props.twoTile ? 10 : 20),
            },
        },
        detailButton: (props) => ({
            cursor: props.isAvailable ? 'pointer' : 'not-allowed',
            fontSize: props.twoTile ? 10 : 'inherit',
            borderRadius: props.isPortraitMode
                ? props.calledByCarousel || props.calledByFloorplan
                    ? '0 0 20px 20px'
                    : '0 0 15px 15px'
                : 'none',
        }),

        availabilityText: () => ({
            padding: '5px 10px',
            borderRadius: '20px',
            margin: '5px 0',
            fontSize: '12px',
            fontWeight: 600,
            color: 'white',
            height: '28px',
            textTransform: 'uppercase',
            width: 'fit-content',
        }),

        soldTag: {
            backgroundColor: '#d24d2f',
        },

        unavailableTag: {
            backgroundColor: '#d24d2f',
        },

        inProgressTag: {
            backgroundColor: '#d24d2f',
        },

        finalUnitTag: {
            backgroundColor: '#6c7079',
        },

        finalHomeTag: {
            backgroundColor: '#6c7079',
        },
    },
    {
        name: 'UnitDetailCardGeneric',
    },
);

function renderUnitDetailCardGeneric(
    project,
    dataUnit,
    isPortraitMode,
    classes,
    error,
    toggleUnitDetailPaneFcn,
    hooks,
    location,
    isAvailable,
    useUnit,
    props,
    isSendToEmailOpen,
    setSendToEmailOpen,
    isTouchScreen
) {
    const doDisplayCard = true;
    const apiUri = NetData.apiUri;
    const ThemeData = project.ThemeData;
    const floorPlanPhoto = getImageBySize(dataUnit.floorPlan.photo, 'small');

    const showAvailability = project.settings?.showAvailability ?? false;

    const virtualTourUrl = dataUnit.virtualTour
        ? dataUnit.virtualTour
        : dataUnit.floorPlan.virtualTour
            ? dataUnit.floorPlan.virtualTour
            : project.settings?.threeDUrl
                ? project.settings?.threeDUrl
                : null;

    const viewStudyUrl = dataUnit.viewStudyUrl
        ? dataUnit.viewStudyUrl
        : dataUnit.floorPlan.viewStudyUrl
            ? dataUnit.floorPlan.viewStudyUrl
            : project.settings?.viewStudyUrl
                ? project.settings?.viewStudyUrl
                : null;

    const unitOrFloorplanStatus = useUnit
        ? dataUnit.status
        : dataUnit.floorPlanStatus;

    const marketingPdf = dataUnit?.marketingPdf?.[0]?.link;

    return (
        <motion.div
            className={`${props.className} ${classes.wrapper} ${
                doDisplayCard ? '' : classes.hidden
            } ${
                dataUnit.threeDId === props.selectedUnitId && props.isToAllowSelection
                    ? classes.selectedUnitHighlight
                    : ''
            }`}
            onClick={() => {
                props.setSelectedUnitId(dataUnit.threeDId);
                if (props.setInteractModalFrom) props.setInteractModalFrom('carousel');
            }}
        >
            <div
                className={`${
                    isPortraitMode
                        ? classes.floorplanImagePortrait
                        : classes.floorplanImage
                }`}
            >
                {dataUnit && dataUnit.floorPlan && dataUnit.floorPlan.photo && (
                    <img src={`${floorPlanPhoto.link}`} alt={dataUnit.floorPlan.name}/>
                )}
                {(!dataUnit || !dataUnit.floorPlan || !dataUnit.floorPlan.photo) && (
                    <img
                        src={`${apiUri}/uploads/floorplan_placeholder_85dd01fc30.png`}
                        alt="floorplan placeholder"
                        height={225}
                    />
                )}
            </div>
            <div className={classes.details}>
                {showAvailability &&
                    <div
                        className={`${classes.availabilityText} ${
                            classes[getStatusClass(unitOrFloorplanStatus)]
                        }`}
                    >
                        {unitOrFloorplanStatus.replace(/([A-Z])/g, ' $1').toUpperCase()}
                    </div>
                }

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 5,
                        alignItems: 'center',
                    }}
                >
                    <UnitInfoPlanNameTitle
                        project={project}
                        data={dataUnit}
                        fontSize={36}
                        doShowUnitNumber={true}
                        className={classes.unitInfoPlanNameTitle}
                    />

                    <div style={{marginLeft: 'auto', display: 'flex', gap: 10}}>
                        {!isTouchScreen && marketingPdf &&
                            <a href={marketingPdf}><img src={DownloadIcon} alt="download"/></a>}
                        {marketingPdf && (
                            <img src={MailIcon} alt="Mail" onClick={() => setSendToEmailOpen(true)}
                                 style={{cursor: 'pointer'}}/>
                        )}
                        <BookMarkButton
                            ThemeData={ThemeData}
                            hooks={hooks}
                            dataUnit={dataUnit}
                            height={18}
                            width={15}
                        />
                    </div>
                </div>
                <div className={classes.unitInfoLine}>
                    {dataUnit && (
                        <UnitInfoLine
                            project={project}
                            donotShowPlanName={props.donotShowPlanName}
                            data={dataUnit}
                        />
                    )}
                </div>
            </div>
            {(viewStudyUrl || virtualTourUrl || project.slug === 'revo') &&
                !isPortraitMode && (
                    <div
                        className={classes.buttonArea}
                        style={{height: `${project.slug === 'revo' ? '50px' : 'auto'}`}}
                    >
                        <ButtonGroup
                            justifyContent="center"
                            className={classes.buttonGroup}
                        >
                            {viewStudyUrl && (
                                <ButtonOutline
                                    ThemeData={ThemeData}
                                    id="enjoy-the-views"
                                    label="Enjoy the View"
                                    className={classes.buttonOutline}
                                    textColour={ThemeData.colours.primaryAccent}
                                    hoverTextColour={ThemeData.colours.white}
                                    borderColour={ThemeData.colours.grey25}
                                    to="."
                                    onClick={() => {
                                        if (!isAvailable) {
                                            return;
                                        }
                                        props.setFloorplanViewStudy(viewStudyUrl);
                                        props.toggleIsViewStudySlideUpOpenFcn();
                                    }}
                                    firstInColl
                                />
                            )}

                            {virtualTourUrl && (
                                <ButtonOutline
                                    ThemeData={ThemeData}
                                    id="take-the-tour"
                                    label="Take the Tour"
                                    className={classes.buttonOutline}
                                    textColour={ThemeData.colours.primaryAccent}
                                    hoverTextColour={ThemeData.colours.white}
                                    borderColour={ThemeData.colours.grey25}
                                    onClick={() => {
                                        props.setFloorplanVirtualTour(virtualTourUrl);
                                        props.toggleVirtualTourPaneFcn();
                                    }}
                                    to="."
                                />
                            )}
                        </ButtonGroup>
                    </div>
                )}
            <div className={classes.moreDetailsButtonArea}>
                <ButtonOutline
                    ThemeData={ThemeData}
                    id={`more-details-button-unit-card-${dataUnit.threeDId}`}
                    label="More Details"
                    className={classes.detailButton}
                    padding={props.twoTile ? 10 : 20}
                    textColour={ThemeData.colours.primaryAccent}
                    hoverTextColour={ThemeData.colours.white}
                    border="none"
                    backgroundColour={ThemeData.colours.neuGray}
                    onClick={() => {
                        if (!isAvailable) {
                            return;
                        }
                        if (dataUnit.__typename === 'Unit') {
                            hooks.setSelectedUnitId(dataUnit.threeDId);
                            hooks.setSelectedFloorplanId(-1);
                        } else {
                            hooks.setSelectedUnitId(-1);
                            hooks.setSelectedFloorplanId(dataUnit.id);
                        }
                        toggleUnitDetailPaneFcn();
                        dataUnit.unit_plan_type &&
                        sendGoogleAnalyticsEvent(
                            `Floor Plan View - ${location.pathname}`,
                            `User Clicks ${dataUnit.unit_plan_type.data.attributes.name} Unit ${dataUnit.unit_number}`,
                            `${dataUnit.unit_plan_type.data.attributes.name} Unit ${dataUnit.unit_number}`,
                            `${dataUnit.unit_plan_type.data.attributes.name} Unit ${dataUnit.unit_number}`,
                        );
                    }}
                />
            </div>
            <FormModal
                project={project}
                doShow={isSendToEmailOpen}
                toggleIsOpen={() => setSendToEmailOpen(prevState => !prevState)}
                sendType="floorplan"
                titleText="Send floorplan to email"
                fileUrl={marketingPdf}
            />
        </motion.div>
    );
}

function UnitDetailCardGeneric({
                                   project,
                                   dataUnit,
                                   toggleUnitDetailPaneFcn,
                                   ...props
                               }) {
    const location = useLocation();
    const [isSendToEmailOpen, setSendToEmailOpen] = useState(false);
    const isPortraitMode = portraitMode();
    const {isBigScreen,isTouchScreen} = useContext(UnitFcnContext);

    // if not showing units, showing a floorplan
    const showUnits = project.settings.showUnits ?? false;

    const useUnit =
        showUnits ||
        location.pathname.includes('/interactive') ||
        location.pathname.includes('/proximation');

    const isAvailable = useUnit
        ? isAvailableStatus(dataUnit.status)
        : isAvailableStatus(dataUnit.floorPlanStatus);

    const classes = useStyles({
        isAvailable: isAvailable,
        ThemeData: project.ThemeData,
        ...props,
        isPortraitMode,
    });

    const {savedHomes, toggleSavedHome, savedHomesIncludes} = useSavedHomes();
    const {toggleCompareHome} = useCompareHomes();

    let hooks = {
        savedHomes,
        toggleSavedHome,
        savedHomesIncludes,
        toggleCompareHome,
        setSelectedUnitId: props.setSelectedUnitId,
        setSelectedFloorplanId: props.setSelectedFloorplanId,
    };

    return renderUnitDetailCardGeneric(
        project,
        dataUnit,
        isPortraitMode,
        classes,
        null,
        toggleUnitDetailPaneFcn,
        hooks,
        location,
        isAvailable,
        useUnit,
        props,
        isSendToEmailOpen,
        setSendToEmailOpen,
        isBigScreen,
        isTouchScreen
    );
}

UnitDetailCardGeneric.defaultProps = {
    isToAllowSelection: false,
};

export default UnitDetailCardGeneric;
